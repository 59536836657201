/*eslint-disable*/
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { asyncDelay } from '../utils/utils';

function AppleSignUpFailureScreen() {
  const initRef = React.useRef(false);
  const urlParams = useParams();

  const [displayStatus, setDisplayStatus] = React.useState('Init');

  const captureAppleFailure = async () => {
    setDisplayStatus('Checking for issues...');
    await asyncDelay(1000);
    setDisplayStatus(
      urlParams?.errorJson
        ? 'Retrieving error data...'
        : 'No error data found.',
    );
    let errorData = null;
    let errorCaptureStatus = false;
    await asyncDelay(1000);
    try {
      errorData = JSON.parse(atob(urlParams?.errorJson));
      errorCaptureStatus = true;
      setDisplayStatus('Error data retrieved successfully.');
      await asyncDelay(1000);
      setDisplayStatus('Verifying issues...');
      await asyncDelay(1000);
    } catch (e) {
      setDisplayStatus('Failed to parse error data.');
      errorData = null;
      errorCaptureStatus = 'error';
    }
    // Send data to webview
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        appleSignInFailure: {
          errorJson: errorData,
          errorJsonRaw: urlParams?.errorJson,
          errorCaptureStatus,
        },
      }),
    );
  };

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
      captureAppleFailure();
    }
  }, [urlParams]);

  return (
    <div className="appleConnect">
      <h1>Apple SignUp</h1>
      <div id="connect-status">
        <p>{displayStatus}</p>
      </div>
    </div>
  );
}
export default AppleSignUpFailureScreen;
