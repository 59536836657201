import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_NAMES } from './routeNames';

import AppleSignUpScreen from '../screens/AppleSignUp';
import AppleMusicConnectScreen from '../screens/AppleMusicConnect';
import PopupCheckScreen from '../screens/popupCheck';
import AppleSignUpSuccessScreen from '../screens/AppleSignUpSuccess';
import AppleSignUpFailureScreen from '../screens/AppleSignUpFailure';

const routes = [
  {
    path: ROUTE_NAMES.appleSignUp,
    index: true,
    element: <AppleSignUpScreen />,
  },
  {
    path: ROUTE_NAMES.appleSignUpSuccess,
    element: <AppleSignUpSuccessScreen />,
  },
  {
    path: ROUTE_NAMES.appleSignUpFailure,
    element: <AppleSignUpFailureScreen />,
  },
  {
    path: ROUTE_NAMES.appleSignUpFailureWithJson,
    element: <AppleSignUpFailureScreen />,
  },
  {
    path: ROUTE_NAMES.appleMusicConnect,
    element: <AppleMusicConnectScreen />,
  },
  {
    path: ROUTE_NAMES.popupCheck,
    element: <PopupCheckScreen />,
  },
];

const router = createBrowserRouter(routes);

export default router;
