import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import { BrowserRouter } from 'react-router-dom';
import AppleAPP from './appleSignUp/App';
import { StoreProvider } from './StoreContext';

const ConditionalLayApp = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  window.location.href.includes('apple') ? (
    <AppleAPP />
  ) : (
    <Suspense fallback={null}>
      <StoreProvider>
        <BrowserRouter>
          <ConditionalLayApp />
        </BrowserRouter>
      </StoreProvider>
    </Suspense>
  ),
);
