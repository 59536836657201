import { useEffect } from 'react';

function PopupCheckScreen() {
  useEffect(() => {
    window.close();
    window.self.close();
  }, []);
  return null;
}

export default PopupCheckScreen;
