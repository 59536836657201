/*eslint-disable*/
import React, { useEffect } from 'react';
import axios from 'axios';
import { ROUTE_NAMES } from '../navigation/routeNames';
import { APP_CONFIG } from '../config/config';
import { asyncDelay } from '../utils/utils';

const baseUrlV2 = APP_CONFIG.apiServerUrl;

// prevent popups from opening in new window (https://developer.apple.com/forums/thread/674118)
const checkIfPopupsBlocked = false;
window.open = function (url, windowName, windowFeatures) {
  console.log('window caught', url, windowName, windowFeatures);
  window.location.href = url;
  return null;
};

function AppleMusicConnectScreen() {
  const initRef = React.useRef(false);

  const [displayStatus, setDisplayStatus] = React.useState('Init');
  const [authFailed, setAuthFailed] = React.useState(false);

  const [popupsBlocked, setPopupsBlocked] = React.useState(false);

  const makeRecentlyPlayedRequest = async () => {
    const makeCall = true;
    if (makeCall) {
      const music = window.MusicKit.getInstance();

      const { data: result } = await music.api.music('v1/me/recent/played/tracks');
      console.log('[AppleConnectScreen] /v1/me/recent/played/tracks Response:', result);
      setDisplayStatus(
        result
          ? 'Retrieved Apple MusicKit - Recently Played Tracks'
          : 'Error retrieving Apple MusicKit - Recently Played Tracks',
      );
      if (result) {
        // Send data to webview
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ appleMusicRecentlyPlayed: result }),
        );
      }
    }
  };

  const importMusicKit = async () => {
    console.log('[AppleConnectScreen] importMusicKit() called');

    // Check if popups are blocked
    if (!window.ReactNativeWebView && checkIfPopupsBlocked) {
      const newWin = window.open(ROUTE_NAMES.popupCheck);
      if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
        setPopupsBlocked(true);
      }
      console.log('[AppleConnectScreen] newWin:', newWin);
      newWin?.close();
    }

    const handleAppleMUTResponse = (res) => {
      console.log('[AppleConnectScreen] handleAppleMUTResponse() called');
      // Send data to webview
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ appleMUT: res }));
    };

    setDisplayStatus('Retrieving Apple Developer Token...');
    const res = await axios.get(`${baseUrlV2}/apple-developer-token/`);
    console.log('[AppleConnectScreen] Token API Response:', res);

    if (res?.data?.payload?.developer_token) {
      const {
        data: {
          payload: { developer_token },
        },
      } = res;

      // Create a script element to load the MusicKit JS in the document body
      setDisplayStatus('Loading Apple MusicKit JS...');
      const script = document.createElement('script');
      script.src = APP_CONFIG.appleMusicKitJsUrl;
      script.async = true;
      script.onload = () => {
        // window.MusicKit.configure({
        // });
      };
      document.body.appendChild(script);

      // Listen for the loaded event
      document.addEventListener('musickitloaded', async function () {
        console.info('[AppleConnectScreen] musickitloaded event fired');
        setDisplayStatus('Apple MusicKit JS loaded');

        // Call configure() to configure an instance of MusicKit on the Web.
        try {
          setDisplayStatus('Configuring Apple MusicKit...');
          await window.MusicKit.configure({
            developerToken: developer_token,
            app: {
              name: 'Musotic Web App',
              // build: '1978.4.1',
            },
          });
          console.log('[AppleConnectScreen] MusicKit.configure() called');
          setDisplayStatus('Apple MusicKit configured');
        } catch (err) {
          // Handle configuration error
          console.error('[AppleConnectScreen] MusicKit.configure() error:', err);
          setDisplayStatus('Apple MusicKit configuration error');
        }

        // MusicKit instance is available
        const music = window.MusicKit.getInstance();
        console.log('[AppleConnectScreen] music:', music);

        // Check authorization status
        const { isAuthorized } = music;
        console.log('[AppleConnectScreen] music.isAuthorized:', isAuthorized);
        if (isAuthorized) {
          setDisplayStatus('Apple MusicKit already authorized');
          music.authorize().then((res) => {
            handleAppleMUTResponse(res);
          });

          // Make a request to the Apple Music API
          await asyncDelay(2000);
          makeRecentlyPlayedRequest();
        } else {
          // unauthorize
          await music.unauthorize();
          console.log('[AppleConnectScreen] music.unauthorize() called');

          // You should check authorization before accessing user's iCloud Music Library:
          setDisplayStatus(`Authorizing Apple MusicKit...`);
          try {
            const authorizeResult = await music.authorize();
            handleAppleMUTResponse(authorizeResult);
            console.info('[AppleConnectScreen] music.authorize() called', authorizeResult);
            setDisplayStatus('Apple MusicKit authorized');

            // Make a request to the Apple Music API
            makeRecentlyPlayedRequest();
          } catch (err) {
            console.error('[AppleConnectScreen] music.authorize() error:', err);
            setDisplayStatus('Apple MusicKit authorization failed.');
            setAuthFailed(true);
          }
        }
      });
    }
  };

  useEffect(() => {
    // Initialise Apple MusicKit only once
    if (!initRef.current) {
      initRef.current = true;
      importMusicKit();
    }
  }, []);

  return (
    <div className="appleConnect">
      <h1>Apple Connect</h1>
      <div id="connect-status">
        <p>
          {displayStatus}
          {popupsBlocked ? ' (Popups blocked)' : ''}
        </p>
        {!!authFailed && (
          <p>
            <a href={ROUTE_NAMES.appleMusicConnect}>Try again</a>
          </p>
        )}
      </div>
    </div>
  );
}
export default AppleMusicConnectScreen;
