/*eslint-disable*/
import React, { useEffect } from 'react';
import axios from 'axios';
import { APP_CONFIG } from '../config/config';
import { ROUTE_NAMES } from '../navigation/routeNames';
import { asyncDelay } from '../utils/utils';

const baseUrlV2 = APP_CONFIG.apiServerUrl;

// prevent popups from opening in new window (https://developer.apple.com/forums/thread/674118)
const checkIfPopupsBlocked = false;
window.open = function (url, windowName, windowFeatures) {
  console.log('window caught', url, windowName, windowFeatures);
  window.location.href = url;
  return null;
};

function AppleSignUpScreen() {
  const initRef = React.useRef(false);

  const [displayStatus, setDisplayStatus] = React.useState('Init');
  const [authSuccess, setAuthSuccess] = React.useState(false);
  const [authFailed, setAuthFailed] = React.useState(false);

  const [popupsBlocked, setPopupsBlocked] = React.useState(false);

  const importAppleJs = async () => {
    console.log('[AppleSignUpScreen] importAppleJs() called');

    // Check if popups are blocked
    if (!window.ReactNativeWebView && checkIfPopupsBlocked) {
      const newWin = window.open(ROUTE_NAMES.popupCheck);
      if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
        setPopupsBlocked(true);
      }
      console.log('[AppleSignUpScreen] newWin:', newWin);
      newWin?.close();
    }

    setDisplayStatus('Retrieving prerequisites...');
    const res = await axios.get(`${baseUrlV2}/apple/get-new-nonce/`);

    if (res?.data?.payload?.nonce) {
      const {
        data: {
          payload: { nonce },
        },
      } = res;
      // Send data to webview
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ appleSignUpNonce: nonce }));
      setDisplayStatus('Prerequisites received, preparing...');
      await asyncDelay(2000);

      // Create a script element to load the Apple JS in the document body
      setDisplayStatus('Loading Apple JS...');
      const script = document.createElement('script');
      script.src = APP_CONFIG.appleJsUrl;
      script.async = true;
      script.onload = () => {
        console.info('[AppleSignUpScreen] Apple JS loaded');
        setDisplayStatus('Apple JS loaded');

        setDisplayStatus('Configuring Apple JS...');
        window.AppleID.auth.init({
          clientId: 'com.musotic.app.service',
          scope: 'name email',
          redirectURI: `${APP_CONFIG.apiServerAppleJsSignUpCallback}`,
          // state: '[STATE]',
          nonce,
          usePopup: false, // TODO: Facilitate support of usePopup: !window.ReactNativeWebView,
          // usePopup: !window.ReactNativeWebView,
          responseType: 'code id_token',
          responseMode: 'form_post', // 'form_post' | 'fragment' | 'query'
        });
        window.AppleID.auth.signIn();
      };
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
      importAppleJs();
    }
  }, []);

  return (
    <div className="appleConnect">
      <h1>Apple SignUp</h1>
      <div id="connect-status">
        <p>
          {displayStatus}
          {popupsBlocked ? ' (Popups blocked)' : ''}
        </p>
        {!!authFailed && (
          <p>
            <a href={ROUTE_NAMES.appleSignUp}>Try again</a>
          </p>
        )}
      </div>
      {!authSuccess && (
        <div
          id="appleid-signin"
          data-color="black"
          data-border="true"
          data-height="45"
          data-type="sign in"
        />
      )}
    </div>
  );
}
export default AppleSignUpScreen;
