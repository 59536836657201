/*eslint-disable*/
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { asyncDelay } from '../utils/utils';

function AppleSignUpSuccessScreen() {
  const initRef = React.useRef(false);
  const urlParams = useParams();

  const [displayStatus, setDisplayStatus] = React.useState('Init');

  const captureAppleSuccessNonce = async () => {
    setDisplayStatus('Verifying...');
    await asyncDelay(1000);
    if (urlParams?.nonce) {
      setDisplayStatus('Finishing verification...');
      // Send data to webview
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ appleSignInSuccess: { nonce: urlParams?.nonce } }),
      );
    }
  };

  useEffect(() => {
    if (!!urlParams?.nonce && !initRef.current) {
      initRef.current = true;
      captureAppleSuccessNonce();
    }
  }, [urlParams]);

  return (
    <div className="appleConnect">
      <h1>Apple SignUp</h1>
      <div id="connect-status">
        <p>{displayStatus}</p>
      </div>
    </div>
  );
}
export default AppleSignUpSuccessScreen;
